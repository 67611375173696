import { useState, useEffect } from "react";
import { createTheme, hexToRgb, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Container, TypoGraph, Card, Loader, Icon } from "components";
import {
  CompanyCodeComponent,
  FooterComponent,
  TransactionForm,
  StepsComponent,
  CompanyLogoComponent,
} from "./partial";
import { fontStyle, receivableTheme, snippetCardStyles } from "./util";
import styles from "./receivables.module.scss";
import strings from "strings";
import {
  DropdownOptions,
  ICompanyWebBasedReceivablesDetails,
  IWebReceivablePaymentData,
  IWhitelabelConfigurationResponse,
} from "interfaces";
import { getWhitelabelConfigBySubDomain } from "api";
import colors from "theme/colors";
import ItemWithSubList from "./nav/mobileNavBar/itemWithSubList";
import { useNavigate, useLocation } from "react-router";
import { lightenColor } from "tools/lightenColor";

const st = strings.WEB_RECEIVABLES;

interface IReceivablesProps {
  isSnippet?: boolean;
}

const Receivables: React.FunctionComponent<IReceivablesProps> = (
  props: IReceivablesProps
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isSnippet } = props;
  const [screenHeight, setScreenHeight] = useState(
    document.documentElement.scrollHeight
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showTransactionForm, setShowTransactionForm] = useState(false);
  const [paymentData, setPaymentData] = useState<IWebReceivablePaymentData>({
    companyId: 0,
    companyCode: "",
    companyName: "",
    paymentType: "",
    availablePaymentMethods: [],
    paymentTypeId: 0,
    paymentRequested: undefined,
  });
  const [whitelabelConfiguration, setWhitelabelConfiguration] =
    useState<IWhitelabelConfigurationResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showSideBar, setShowSidebar] = useState<boolean>(false);
  const [validCompanyCode, setValidCompanyCode] = useState<boolean>(false);

  const [companyData, setCompanyData] =
    useState<ICompanyWebBasedReceivablesDetails>({
      id: 0,
      name: "",
      paymentTypes: [],
      chargesCostToBuyer: false,
    });

  const [availableCompanies, setAvailableCompanies] = useState<
    DropdownOptions[]
  >([]);

  //Use effect on page load
  useEffect(() => {
    getWhitelabelConfigBySubDomain()
      .then((response) => {
        setWhitelabelConfiguration(response?.data);
        // Update window title
        document.title = response?.data.configJson.pageName
          ? response?.data.configJson.pageName
          : "Bankshot Portal";
        // Update favicon
        const favicon = document.querySelector("link[rel='icon']");
        if (favicon instanceof HTMLLinkElement) {
          if (!response.data.configJson.showDefaultFavIcon) {
            favicon.href = `data:image/png;base64,${response?.data.favIconBase64}`;
          }
        }
        //This behavior will only be used on /receivables path, if url already contains the company code, it will skip this code.
        if (
          location.pathname === "/receivables" ||
          location.pathname === "/receivables/"
        ) {
          const associatedCompanies = response.data.companies;
          if (!associatedCompanies || associatedCompanies.length == 0) {
            // Nothing, navigates to receivables
            return;
          } else if (associatedCompanies.length == 1) {
            // Navigates to the receivables page of the only company
            navigate(
              isSnippet
                ? `/snippet/receivables/${associatedCompanies[0].companyCode}`
                : `/receivables/${associatedCompanies[0].companyCode}`
            );
          } else {
            // Shows dropdown with available companies
            let companyOptions = associatedCompanies.map((company) => {
              return {
                value: company.companyCode,
                label: company.name,
              };
            });
            setAvailableCompanies(companyOptions);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // sets the window width of the screen
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    function handleResize() {
      setScreenHeight(
        window.innerHeight +
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      );
    }

    window.addEventListener("resize", handleResize);
    document.addEventListener("scroll", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("scroll", handleResize);
    };
  }, []);

  const handlePaymentTypes = (payload: IWebReceivablePaymentData) => {
    setPaymentData(payload);
    setShowTransactionForm(true);
  };

  const handleCompanyData = (payload: ICompanyWebBasedReceivablesDetails) => {
    setCompanyData(payload);
    setShowTransactionForm(true);
  };

  const theme = createTheme({
    ...receivableTheme,
    palette: {
      primary: {
        main: whitelabelConfiguration?.configJson.backgroundColor
          ? whitelabelConfiguration?.configJson.backgroundColor
          : colors.primary,
      },
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: whitelabelConfiguration?.configJson.backgroundColor
              ? whitelabelConfiguration?.configJson.backgroundColor
              : colors.primary,
          },
        },
      },
    },
  });

  const backgroundColor = () => {
    const whitelabelBgColor =
      whitelabelConfiguration?.configJson.backgroundColor;
    if (whitelabelBgColor) {
      return lightenColor(
        whitelabelConfiguration.configJson.backgroundColor,
        95
      );
    }

    return colors.wbrBackground;
  };

  const shadowColor = () => {
    const whitelabelBgColor =
      whitelabelConfiguration?.configJson.backgroundColor;
    const rbgColor = hexToRgb(
      whitelabelBgColor ? whitelabelBgColor : colors.primary
    );

    return `0px 0px 25px -10px  ${rbgColor} !important`;
  };
  return (
    <ThemeProvider theme={theme}>
      <Loader loading={loading} />
      {!isSnippet && showSideBar && (
        <div className={styles.sidebar}>
          <Container className={styles.sidebarHeaderContainer}>
            <img
              id={"logoBase64Sidebar"}
              height={45}
              src={`data:image/png;base64,${whitelabelConfiguration?.logoBase64}`}
              alt={"vector_images"}
            />
            <Icon
              name="icon_cancel"
              size={16}
              iconButton
              onClick={() => setShowSidebar(false)}
            />
          </Container>
          {whitelabelConfiguration?.configJson?.navBarActionItems?.map(
            (item, index) =>
              item.subItems && item.subItems.length > 0 ? (
                <ItemWithSubList item={item} index={index} />
              ) : (
                <>
                  <TypoGraph
                    key={index}
                    content={item.content}
                    className={styles.navItem}
                    onClick={() => {
                      window.open(item.url);
                    }}
                    link
                  />
                </>
              )
          )}
        </div>
      )}
      <CssBaseline />
      {isSnippet && (
        <Container
          className={styles.receivablesContainerSnippet}
          sx={{ height: windowWidth > 1000 ? screenHeight : "auto" }}
        >
          <Container className={styles.cardAndFooter}>
            <Card
              className={styles.responsiveCard}
              sxContainer={snippetCardStyles}
            >
              {!showTransactionForm ? (
                <CompanyCodeComponent
                  handleValidCompanyCode={() => {
                    setValidCompanyCode(true);
                  }}
                  handlePaymentTypes={handlePaymentTypes}
                  handleCompanyData={handleCompanyData}
                  windowWidth={windowWidth}
                  isSnippet={true}
                  whitelabelConfiguration={whitelabelConfiguration?.configJson}
                  availableCompanies={availableCompanies}
                />
              ) : (
                <TransactionForm
                  whitelabelConfiguration={whitelabelConfiguration}
                  paymentData={paymentData}
                  companyData={companyData}
                  handleValidCompanyCode={() => {
                    setValidCompanyCode(false);
                  }}
                  handlePaymentTypes={handlePaymentTypes}
                  isSnippet={true}
                  handleShowPaymentType={() => setShowTransactionForm(false)}
                />
              )}
              <TypoGraph
                content="Powered by Bank Shot"
                sx={{ ...fontStyle.body1, textAlign: "center" }}
              />
            </Card>
            <Container className={styles.footer}>
              {!validCompanyCode && <StepsComponent />}
              <FooterComponent
                whitelabelConfig={whitelabelConfiguration?.configJson}
              />
            </Container>
          </Container>
        </Container>
      )}
      {!isSnippet && (
        <Container
          className={styles.receivablesContainer}
          sx={{
            height: windowWidth > 1000 ? screenHeight : "auto",
            backgroundColor: backgroundColor(),
          }}
        >
          <Container className={styles.cardAndFooter}>
            <Card
              className={styles.responsiveCard}
              sxContainer={{
                boxShadow: shadowColor(),
                borderRadius: "16px",
              }}
            >
              <CompanyLogoComponent
                whitelabelConfig={whitelabelConfiguration}
              />
              {!showTransactionForm ? (
                <CompanyCodeComponent
                  handleValidCompanyCode={() => {
                    setValidCompanyCode(true);
                  }}
                  handlePaymentTypes={handlePaymentTypes}
                  handleCompanyData={handleCompanyData}
                  windowWidth={windowWidth}
                  whitelabelConfiguration={whitelabelConfiguration?.configJson}
                  availableCompanies={availableCompanies}
                />
              ) : (
                <TransactionForm
                  paymentData={paymentData}
                  companyData={companyData}
                  handleValidCompanyCode={() => {
                    setValidCompanyCode(false);
                  }}
                  handlePaymentTypes={handlePaymentTypes}
                  whitelabelConfiguration={whitelabelConfiguration}
                  handleShowPaymentType={() => setShowTransactionForm(false)}
                />
              )}
            </Card>
            <Container className={styles.footer}>
              {!validCompanyCode && (
                <StepsComponent
                  whitelabelConfig={whitelabelConfiguration?.configJson}
                />
              )}
              <FooterComponent
                whitelabelConfig={whitelabelConfiguration?.configJson}
              />
            </Container>
          </Container>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default Receivables;
