import { Container, Icon, TypoGraph } from "components";
import colors from "theme/colors";
import styles from "./sendPayment.module.scss";
import {
  IWebReceivablePaymentData,
  IWhitelabelConfigurationResponse,
} from "interfaces";
import { useFormContext } from "react-hook-form";
import { locatedInData, propertyAddress } from "tools/format";
import { paymentMethods } from "constants/paymentMethods";

interface ISendPaymentProps {
  paymentData: IWebReceivablePaymentData;
  whitelabelConfiguration?: IWhitelabelConfigurationResponse;
}

const SendPayment: React.FC<ISendPaymentProps> = (props) => {
  const { paymentData, whitelabelConfiguration } = props;

  const { getValues } = useFormContext();
  const formValues = getValues();

  const finalAmount = (): string => {
    const amountAsNumber = parseFloat(formValues.amount);
    const feeAsNumber =
      getValues("paymentMethod") == paymentMethods.ELECTRONIC_PAYMENT
        ? parseFloat(paymentData.achFee!.substring(1))
        : 0;
    const dueFee = paymentData.paymentRequested?.prDue
      ? parseFloat(dueFeeValue().replace("$", ""))
      : 0;

    if (!isNaN(amountAsNumber)) {
      const result = amountAsNumber + feeAsNumber + dueFee;
      return `$${result.toFixed(2)}`;
    } else {
      return "Invalid amount";
    }
  };

  const dueFeeValue = (): string => {
    const dueFee = paymentData.paymentRequested?.prFeeAmount!;
    const amount = parseInt(
      paymentData.paymentRequested?.amount.replace(".", "")!
    );
    const feeAmount = dueFee - amount;
    const feeAmountFixed = (feeAmount / 100).toFixed(2);
    return `$${feeAmountFixed}`;
  };

  return (
    <Container className={styles.sendPaymentContainer}>
      <Container className={styles.contentContainer}>
        <span id="scrollElement"></span>
        {whitelabelConfiguration?.backgroundBase64 ? (
          <Icon
            type="images"
            url={`data:image/png;base64,${whitelabelConfiguration?.backgroundBase64}`}
            size={whitelabelConfiguration?.configJson.successIconSize}
            boxClassName={styles.successWhitelabel}
          />
        ) : (
          <Icon
            name="icon_partyPopper"
            size={100}
            boxClassName={styles.partyIcon}
          />
        )}
        <Container>
          <TypoGraph className={styles.paymentInfo}>
            <TypoGraph component="span" content="Your " />
            <TypoGraph
              component="span"
              color={
                whitelabelConfiguration?.configJson.backgroundColor
                  ? whitelabelConfiguration.configJson.backgroundColor
                  : colors.primary
              }
              content={`${finalAmount()} `}
            />
            <TypoGraph component="span" content="payment to " />
            <TypoGraph
              component="span"
              color={
                whitelabelConfiguration?.configJson.backgroundColor
                  ? whitelabelConfiguration.configJson.backgroundColor
                  : colors.primary
              }
              content={paymentData.companyName + " "}
            />
            <TypoGraph component="span" content="for " />
            <TypoGraph
              component="span"
              color={
                whitelabelConfiguration?.configJson.backgroundColor
                  ? whitelabelConfiguration.configJson.backgroundColor
                  : colors.primary
              }
              content={`${propertyAddress(formValues)} ${locatedInData(
                formValues
              )} `}
            />
            <TypoGraph component="span" content="was successfully sent." />
          </TypoGraph>
        </Container>
        <Container className={styles.infoText}>
          <TypoGraph
            content={`A confirmation email will be sent to your inbox once ${paymentData.companyName} has
                processed this payment.`}
          />
        </Container>
      </Container>
    </Container>
  );
};
export default SendPayment;
