const colors = {
  primary: "#7851F8",
  primary2: "#4C2C96",
  secondary: "#F6F3FC",
  primaryFont: "#32334C",
  error: "#F9635E",
  success: "#5ABFAE",
  warning: "#F5B529",
  waiting: "#F2B90C",
  subTitle1: "#636363",
  white: "#FFFFFF",
  body: "#F8F8F8",
  grey: "rgb(102, 102, 102)",
  new: "#97E6AC",
  held: "#FFE57E",
  due: "#F5B529",
  grey40: "#666666",
  dark: "#212121",
  lightGrey: "#D1D5DB",
  wbrBackground: "#F7F5FF",
};

export default colors;
