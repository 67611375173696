import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Container,
  Loader,
  StepperComponent,
  Toast as toast,
  TypoGraph,
} from "components";
import BaseDialog from "components/dialog/baseDialog";
import { AuthorizePayment, SendPayment, TransactionDetails } from "./steps";
import { fontStyle } from "../../util";
import styles from "./transactionForm.module.scss";
import strings from "strings";
import {
  DropdownOptions,
  ICompanyWebBasedReceivablesDetails,
  INewReceivableRequest,
  IPayment,
  IPaymentMethod,
  IWBRAgentRequest,
  IWebReceivablePaymentData,
  IWhitelabelConfigurationResponse,
} from "interfaces";
import { getMobileDeviceInformation } from "tools/deviceInformation";
import Decimal from "decimal.js";
import {
  createNewWebBasedReceivable,
  creditCardPassportToken,
  getUsStates,
} from "api";
import { useNavigate } from "react-router";
import {
  formatDateToMM_DD_YYYYFromMM_YYYY,
  formatDateToMMDDYYYYFromYYYY_MM_DD,
} from "tools/format";
import { paymentMethods } from "constants/paymentMethods";
import { DropdownFormField } from "components/hookForm";
import currencyFormatter from "tools/currencyFormatter";
import { fireworksConfetti } from "tools/confetti";
import { ICreditCardPassportTokenRequest } from "interfaces/IPassportToken";
import { electronicPaymentAmountLimitInDollars } from "./steps/authorizePayment/authorizePayment";
import { processorConstants } from "constants/checkCaptureProcessors";

const steps = [
  "Payment Type",
  "Transaction Details",
  "Authorize",
  "Send Payment",
];

const st = strings.WEB_RECEIVABLES;

interface ITransactionFormProps {
  handleShowPaymentType: () => void;
  paymentData: IWebReceivablePaymentData;
  whitelabelConfiguration?: IWhitelabelConfigurationResponse;
  isSnippet?: boolean;
  companyData: ICompanyWebBasedReceivablesDetails;
  handleValidCompanyCode: () => void;
  handlePaymentTypes: (params: {
    companyId: number;
    companyCode: string;
    paymentType: string;
    companyName: string;
    availablePaymentMethods: IPaymentMethod[];
    paymentTypeId: number;
    paymentRequested?: IPayment;
    achFee?: string;
  }) => void;
}

const TransactionForm: React.FC<ITransactionFormProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const disabledPaymentTypesForWBR = [4, 6];
  const {
    handleShowPaymentType,
    paymentData,
    whitelabelConfiguration,
    isSnippet,
    handlePaymentTypes,
    companyData,
    handleValidCompanyCode,
  } = props;
  const [activeStep, setActiveStep] = useState<number>(1);
  const methods = useForm({
    reValidateMode: "onChange",
  });
  const { handleSubmit, setValue, watch, getValues } = methods;
  const [statesOptions, setStatesOptions] = useState<DropdownOptions[]>();
  const [paymentTypes, setPaymentTypes] = useState<DropdownOptions[]>();
  const [isRequestFormPayment, setIsRequestFormPayment] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const webReceivable: INewReceivableRequest = {
    companyId: 0,
    paymentType: 0,
    amountInCents: 0,
    add1: "",
    add2: "",
    city: "",
    state: "",
    postalCode: "",
    frontImageBase64: "",
    backImageBase64: "",
    prNote: "",
    specialinst: "",
    agentRequest: {
      agentEmail: "",
      agentFirstName: "",
      agentLastName: "",
      companyCode: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    achRequest: {
      accountHolder: "",
      accountNumber: "",
      routingNumber: "",
      bankAccountType: "",
      holderType: "",
    },
    creditCardRequest: {
      cardHolder: "",
      token: "",
      cvv: "",
    },
    wireConfirmationImageBase64: "",
  };

  useEffect(() => {
    getUsStates().then((response) => {
      const statesOptions = response?.data?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setStatesOptions(statesOptions);
    });
  }, []);

  useEffect(() => {
    const paymentTypeOptions = companyData.paymentTypes
      .filter(
        (paymentType) => !disabledPaymentTypesForWBR.includes(paymentType.id)
      )
      .map((paymentType) => ({
        value: paymentType.id,
        label: paymentType.name,
      }));
    setPaymentTypes(paymentTypeOptions);
  }, [companyData]);

  function needsSourceOfFunds(paymentMethod: number) {
    switch (paymentMethod) {
      case 1:
        return true;
      case 2:
        return true;
      default:
        return false;
    }
  }

  const fillRequiredFieldsByPaymentType = (
    paymentType: number,
    formData: any
  ) => {
    switch (paymentType) {
      case 8:
      case 7:
      case 0:
      case 10:
      case 14:
        webReceivable.saname = formData.buyerFirstName
          ? formData.buyerFirstName
          : "" + " " + formData.buyerLastName
          ? formData.buyerLastName
          : "";
        webReceivable.laname =
          formData.agentFirstName + " " + formData.agentLastName;
        webReceivable.trrole = +formData.trrole;
        webReceivable.buyerEmail = formData.buyerEmail;
        webReceivable.buyerPhone = formData.buyerPhone;
        webReceivable.name3 = formData.name3;
        if (formData.fileNumber) {
          webReceivable.fileNumber = formData.fileNumber;
        }
        webReceivable.titleCoContact = formData.titleCoContact;
        break;
      case 1:
        webReceivable.trrole = +formData.trrole;
        webReceivable.saname = formData.saname;
        webReceivable.laname = formData.laname;
        webReceivable.petdep = formData.petdep;
        webReceivable.name1 = formData.name1;
        webReceivable.name2 = formData.name2;
        webReceivable.name3 = formData.name3;
        webReceivable.name4 = formData.name4;
        break;
      case 2:
        webReceivable.closeattorney = formData.closeattorney;
        webReceivable.emholder = formData.emholder;
        webReceivable.trrole = +formData.trrole;
        break;
      case 3:
        webReceivable.rentlate = +formData.rentLate;
        webReceivable.xtrafunds = +formData.xtrafunds;
        webReceivable.xfundnote = formData.xfundnote;
        webReceivable.curmonth = +formData.curmonth;
        break;
      case 5:
        webReceivable.otherptype = formData.otherptype;
        break;
      case 9:
        webReceivable.trrole = +formData.trrole;
        webReceivable.saname =
          formData.buyerFirstName + " " + formData.buyerLastName;
        webReceivable.buyerEmail = formData.buyerEmail;
        webReceivable.buyerPhone = formData.buyerPhone;
        webReceivable.name3 = formData.name3;
        webReceivable.titleCoContact = formData.titleCoContact;
        break;
      case 11:
        webReceivable.saname =
          formData.buyerFirstName + " " + formData.buyerLastName;
        webReceivable.laname =
          formData.agentFirstName + " " + formData.agentLastName;
        webReceivable.buyerEmail = formData.buyerEmail;
        webReceivable.invoiceNumber = formData.invoiceNumber;
        break;
      case 12:
        webReceivable.crrAgentName = formData.crrAgentName;
        webReceivable.mlsNumber = formData.mlsNumber;
        break;
      case 13:
        webReceivable.realEstateLicense = formData.realEstateLicense;
        webReceivable.nickname = formData.nickname;
        webReceivable.coveringDate = formData.coveringDate;
        break;
      default:
        break;
    }
  };

  const buildPayload = async (formData: any) => {
    if (!formData) {
      throw new Error("Form data not initialized yet.");
    }
    //Hardcoding required fields
    fillRequiredFieldsByPaymentType(paymentData.paymentTypeId, formData);
    webReceivable.companyId = paymentData.companyId;
    webReceivable.paymentType = paymentData.paymentTypeId;
    webReceivable.closedate = formData.closedate ? formData.closedate : "";
    webReceivable.binddate = formData.binddate ? formData.binddate : "";

    //Hardcoding required property fields
    webReceivable.city = formData.city ? formData.city : "";
    webReceivable.postalCode = formData.postalCode ? formData.postalCode : "";
    webReceivable.state = formData.state ? formData.state : "";
    webReceivable.add1 = formData.add1 ? formData.add1 : "";
    webReceivable.add2 = formData.add2 ? formData.add2 : "";
    webReceivable.prNote = formData.prNote ? formData.prNote : "";
    //Converting dollars to cents
    if (paymentData.paymentRequested?.prDue) {
      webReceivable.amountInCents = paymentData.paymentRequested.prFeeAmount;
    } else {
      webReceivable.amountInCents = new Decimal(formData.amount)
        .times(100)
        .toNumber();
    }

    webReceivable.emamount = formData.emamountInDollars
      ? new Decimal(formData.emamountInDollars).times(100).toNumber()
      : 0;

    webReceivable.xfundamount = formData.xfundamountInDollars
      ? new Decimal(formData.xfundamountInDollars).times(100).toNumber()
      : 0;

    webReceivable.paymentMethod =
      formData?.paymentMethod == undefined || formData?.paymentMethod == null
        ? 0
        : +formData.paymentMethod;

    webReceivable.specialinst = formData.specialinst
      ? formData.specialinst
      : "";

    //Hardcoding required image
    webReceivable.frontImageBase64 =
      webReceivable.paymentMethod === 0 ? formData.frontImageBase64 : "--";
    webReceivable.backImageBase64 =
      webReceivable.paymentMethod === 0 ? formData.backImageBase64 : "--";

    const paymentMethodNeedsSourceOfFunds = needsSourceOfFunds(
      webReceivable.paymentMethod
    );

    if (paymentMethodNeedsSourceOfFunds) {
      webReceivable.sourceOfFunds = formData.sourceOfFunds?.id;
    }
    //Including mobile device information
    webReceivable.mobileDevice = getMobileDeviceInformation();

    let wbrAgent: IWBRAgentRequest = {
      agentEmail: formData.agentEmail,
      agentFirstName: formData.agentFirstName,
      agentLastName: formData.agentLastName,
      companyCode: paymentData.companyCode,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      city: formData.billingUserCity,
      state: formData.billingUserState,
      zipCode: formData.zipCode,
    };
    //Only run this section if payment method is Same Day ACH (passport)
    if (formData.paymentMethod == paymentMethods.ELECTRONIC_PAYMENT) {
      webReceivable.achRequest = {
        accountHolder: formData.accountHolder,
        accountNumber: formData.accountNumber,
        routingNumber: formData.routingNumber,
        bankAccountType: formData.bankAccountType,
        holderType: formData.holderType,
      };
    }
    if (formData.paymentMethod == paymentMethods.CREDIT_CARD) {
      const passportToken = await getCreditCardToken(formData);
      webReceivable.creditCardRequest = {
        cardHolder: formData.cardHolder,
        token: passportToken,
        cvv: formData.cvv,
      };
    }
    if (
      formData.paymentMethod == paymentMethods.CHECK_CAPTURE &&
      companyData.chargesCostToBuyer
    ) {
      webReceivable.achRequest = {
        bankAccountType: formData.bankAccountType,
        holderType: formData.holderType,
        accountHolder: formData.accountHolder,
      };
    }
    webReceivable.agentRequest = wbrAgent;
    webReceivable.paymentRequestId =
      paymentData.paymentRequested?.paymentRequestId;

    webReceivable.wireConfirmationImageBase64 =
      formData.wireConfirmationImageBase64
        ? formData.wireConfirmationImageBase64
        : "";

    return webReceivable;
  };

  const handleStepBack = () => {
    window.scrollTo(0, 0);
    if (activeStep === 1) {
      handleShowPaymentType();
      handleValidCompanyCode();
      navigate(isSnippet ? `/snippet/receivables` : `/receivables`);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const getCreditCardToken = async (formData: any) => {
    let request: ICreditCardPassportTokenRequest = {
      cardNumber: formData.cardNumber,
      expiryMonth: formData.expiryMonth,
      expiryYear: formData.expiryYear,
      holderName: formData.cardHolder,
      cvv: formData.cvv,
      billingAddress: {
        addressLine1: formData.addressLine1,
        city: formData.billingUserCity,
        state: formData.billingUserState,
        zip: formData.zipCode,
        country: "US",
      },
      avs: {
        addressLine1: formData.addressLine1,
        firstName: formData.agentFirstName,
        lastName: formData.agentLastName,
        zip: formData.zipCode,
        phone: formData.cardHolderPhoneNumber,
        email: formData.agentEmail,
      },
      cardHolder: {
        phone: formData.cardHolderPhoneNumber,
        email: formData.agentEmail,
        name: formData.cardHolder,
      },
    };

    const response: any = await creditCardPassportToken(request);
    const data = await response.data;

    if (data.token) {
      return data.token;
    }
  };

  const handleStepNext = async (formData: any) => {
    //User clicked on Send Payment
    if (activeStep === 2) {
      setLoading(true);
      try {
        await buildPayload(formData);
        if (
          formData.paymentMethod == paymentMethods.CREDIT_CARD &&
          webReceivable.creditCardRequest?.token == ""
        ) {
          setLoading(false);
          return;
        }
        await createNewWebBasedReceivable(webReceivable)
          .then((response) => {
            fireworksConfetti();
            toast({
              type: "success",
              title: "Payment has been sent successfully!",
              subTitle: "BASH-ID: " + response?.data?.id,
            });
            setActiveStep(activeStep + 1);
            return;
          })
          .finally(() => {
            setLoading(false);
            window.scrollTo(0, 0);
            setTimeout(() => {
              const scrollElement = document.getElementById("scrollElement");
              if (scrollElement) {
                scrollElement.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
            }, 10);
          });
      } catch {
        setLoading(false);
      }

      return;
    }

    //User clicked on Great!
    if (activeStep === 3) {
      handleShowPaymentType();
      navigate(
        isSnippet
          ? `/snippet/receivables/${paymentData.companyCode}`
          : `/receivables/${paymentData.companyCode}`
      );
      window.scrollTo(0, 0);
      return;
    }

    // Handle case when the payment type has changed and the already selected payment method is not included on the other payment type methods list, will clear the paymentMethod value.
    const selectedPaymentMethodExists =
      paymentData.availablePaymentMethods.find(
        (pm) => pm.id == getValues("paymentMethod")
      ) != undefined;

    if (!selectedPaymentMethodExists) {
      setValue("paymentMethod", undefined);
    }
    setActiveStep(activeStep + 1);
    window.scrollTo(0, 0);
  };

  const onSubmit = (formData: any) => {
    handleStepNext(formData);
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return (
          <TransactionDetails
            paymentData={paymentData}
            whitelabelConfiguration={whitelabelConfiguration?.configJson}
            statesOptions={statesOptions}
          />
        );
      case 2:
        return (
          <AuthorizePayment
            paymentData={paymentData}
            statesOptions={statesOptions}
            whitelabelConfiguration={whitelabelConfiguration}
            companyChargesCostToBuyer={companyData.chargesCostToBuyer}
          />
        );
      case 3:
        return (
          <SendPayment
            paymentData={paymentData}
            whitelabelConfiguration={whitelabelConfiguration}
          />
        );
      default:
        return <></>;
    }
  };

  const renderStepsText = () => {
    switch (activeStep) {
      case 1:
        return (
          <TypoGraph
            className={styles.stepText}
            variant={"body2"}
            content={"📄 Payment details"}
          />
        );
      case 2:
        return (
          <TypoGraph
            className={styles.stepText}
            variant={"body2"}
            content={"💸 Authorize payment"}
          />
        );
      case 3:
        return (
          <TypoGraph
            className={styles.stepText}
            variant={"body2"}
            content={"🎉 Secure payment sent"}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    // Autocompletes the form values for the requested payment if it exists and it's payment type is 0 (EMD)
    const paymentRequested = paymentData.paymentRequested;
    if (!paymentRequested) {
      return;
    }
    setIsRequestFormPayment(true);
    setPaymentTypes([
      { value: paymentData.paymentTypeId, label: paymentData.paymentType },
    ]);

    setValue("amount", paymentRequested.amount);
    const buyerName = paymentRequested.agentName?.split(" ");
    //Agent email, frist name and last name are used in "Your Info" component, they must be same as buyer
    setValue("agentEmail", paymentRequested.buyerEmail);
    setValue("agentFirstName", buyerName ? buyerName[0] : undefined);
    setValue("agentLastName", buyerName ? buyerName[1] : undefined);
    setValue("trrole", paymentRequested.role.toString());
    setValue("add1", paymentRequested.address.address1);
    setValue("add2", paymentRequested.address.address2);
    setValue("city", paymentRequested.address.city);
    setValue("state", paymentRequested.address.state);
    setValue("postalCode", paymentRequested.address.pcode);
    setValue("prNote", paymentRequested.prNote);

    switch (paymentRequested.typeId) {
      case 0:
      case 7:
      case 8:
      case 9:
      case 10:
      case 14:
        setValue("buyerFirstName", buyerName ? buyerName[0] : undefined);
        setValue("buyerLastName", buyerName ? buyerName[1] : undefined);
        setValue("buyerEmail", paymentRequested.buyerEmail);
        setValue("buyerPhone", paymentRequested.buyerPhone);
        setValue("name3", paymentRequested.name3);
        setValue("titleCoContact", paymentRequested.titleCoContact);
        break;
      case 2:
        setValue("emamountInDollars", paymentRequested.emamount);
        setValue("emholder", paymentRequested.role.toString());
        setValue("closeattorney", paymentRequested.closeattorney);
        setValue(
          "closedate",
          formatDateToMMDDYYYYFromYYYY_MM_DD(paymentRequested.closingDate)
        );
        break;
      case 3:
        setValue("rentlate", paymentRequested.rentLate?.toString());
        setValue("xtrafunds", paymentRequested.xtraFunds?.toString());
        setValue("xfundnote", paymentRequested.xfundNote);
        setValue("curmonth", paymentRequested.curMonth?.toString());
        setValue("xfundamountInDollars", paymentRequested.xfundAmount);
        break;
      case 1:
        setValue("laname", paymentRequested.laname);
        setValue("saname", paymentRequested.saname);
        setValue("name1", paymentRequested.name1);
        setValue("name2", paymentRequested.name2);
        setValue("name3", paymentRequested.name3);
        setValue("name4", paymentRequested.name4);
        setValue("petdep", paymentRequested?.petdep?.toString() || "");
        setValue(
          "closedate",
          formatDateToMMDDYYYYFromYYYY_MM_DD(paymentRequested.closingDate)
        );
        setValue(
          "binddate",
          formatDateToMMDDYYYYFromYYYY_MM_DD(paymentRequested.bindDate)
        );
        break;
      case 5:
        setValue("otherptype", paymentRequested.otherPType);
        break;
      case 11:
        setValue("buyerFirstName", buyerName ? buyerName[0] : undefined);
        setValue("buyerLastName", buyerName ? buyerName[1] : undefined);
        setValue("buyerEmail", paymentRequested.buyerEmail);
        setValue("invoiceNumber", paymentRequested.invoiceNumber);
        break;
      case 12:
        setValue("buyerFirstName", buyerName ? buyerName[0] : undefined);
        setValue("buyerLastName", buyerName ? buyerName[1] : undefined);
        setValue("buyerEmail", paymentRequested.buyerEmail);
        setValue("crrAgentName", paymentRequested.crrAgentName);
        setValue("mlsNumber", paymentRequested.mlsNumber);
        setValue(
          "closedate",
          formatDateToMMDDYYYYFromYYYY_MM_DD(paymentRequested.closingDate)
        );
        break;
      case 13:
        setValue("buyerFirstName", buyerName ? buyerName[0] : undefined);
        setValue("buyerLastName", buyerName ? buyerName[1] : undefined);
        setValue("buyerEmail", paymentRequested.buyerEmail);
        setValue("realEstateLicense", paymentRequested.realEstateLicense);
        setValue("nickname", paymentRequested.nickname);
        setValue(
          "coveringDate",
          formatDateToMM_DD_YYYYFromMM_YYYY(paymentRequested.coveringDate)
        );
        break;
      default:
        break;
    }
  }, []);

  const PaymentTypeDropDown = () => {
    const handlePaymentTypeChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setValue("trrole", undefined);
      const selectedPaymentType = {
        value: event.target.value,
        label: event.target.labels,
      };
      const paymentType = companyData.paymentTypes.find(
        (type) => type.id === parseInt(selectedPaymentType.value)
      );
      if (paymentType && paymentType.id !== -1) {
        handlePaymentTypes({
          companyId: paymentData.companyId,
          companyCode: paymentData.companyCode,
          paymentType: paymentType.name,
          paymentTypeId: paymentType.id,
          companyName: companyData.name,
          availablePaymentMethods: paymentType.paymentMethods.map(
            (paymentMethod) => ({
              id: paymentMethod.id,
              name: paymentMethod.name,
              active: true,
            })
          ),
          achFee: paymentType.achFeeInCents
            ? currencyFormatter(
                new Decimal(paymentType.achFeeInCents)
                  .dividedBy(100)
                  .toNumber() + ""
              )
            : "",
        });
      }
    };

    return (
      <Container>
        <FormProvider {...methods}>
          <Container>
            <DropdownFormField
              name="paymentType"
              label={strings.PAYMENT_TYPE}
              disabled={isRequestFormPayment}
              options={
                paymentTypes?.map((paymentType) => ({
                  value: paymentType.value,
                  label: paymentType.label,
                })) || []
              }
              value={paymentData.paymentTypeId ?? ""}
              onChanges={handlePaymentTypeChange}
            />
            {activeStep === 1 && paymentData.paymentTypeId === -1 && (
              <Container>
                <DropdownFormField
                  name={"trrole"}
                  label={"Your role in the transaction *"}
                  disabled={true}
                />
              </Container>
            )}
          </Container>
        </FormProvider>
      </Container>
    );
  };

  return (
    <Container className={styles.mainContainer}>
      <Container className={styles.stepperContainer}>
        <BaseDialog
          open={loading}
          handleClose={() => {}}
          showCancel={false}
          content={
            <Container>
              <TypoGraph
                content={`Sending payment to: ${paymentData.companyName}`}
              />
              <Loader loading={loading} type="default" height="30vh" />
            </Container>
          }
        />
        {renderStepsText()}
        <StepperComponent
          steps={steps}
          color={whitelabelConfiguration?.configJson?.backgroundColor}
          activeStep={activeStep}
          stepperClassName={styles.responsiveStepper}
          handleNext={handleSubmit(onSubmit)}
          handleBack={handleStepBack}
          showPrevBtn={activeStep !== 3}
          showNextBtn={
            activeStep !== 2 || paymentData.availablePaymentMethods.length > 0
          }
          btnLabels={{
            backBtn: "Previous Step",
            nextBtn:
              activeStep === 2 ? "Send Payment" : "Continue to next step",
            submitBtn: "Great!",
          }}
          disableNextBtn={
            (watch("amount") > electronicPaymentAmountLimitInDollars &&
              !paymentData.availablePaymentMethods.some(
                (method) => method.id === paymentMethods.CHECK_CAPTURE
              )) ||
            paymentData.paymentTypeId === -1
          }
          alternativeLabel
        >
          <Container className={styles.cardHeaderContainer}>
            {activeStep == 1 && (
              <TypoGraph className={styles.provideDetailsText}>
                <TypoGraph component="span" content={st.PROVIDE_TXN_DETAILS} />
                <TypoGraph
                  component="span"
                  sx={{ fontWeight: "bold" }}
                  content={paymentData.companyName}
                />
              </TypoGraph>
            )}
            {activeStep == 2 && (
              <TypoGraph
                className={styles.provideDetailsText}
                content={st.SELECT_YOUR_METHOD_AND_AUTHORIZE}
              />
            )}
          </Container>
          {activeStep !== 2 && activeStep !== 3 ? (
            <PaymentTypeDropDown />
          ) : null}
          <Container>
            {paymentData.paymentTypeId !== -1 ? (
              <FormProvider {...methods}>{renderSteps()} </FormProvider>
            ) : null}
          </Container>
        </StepperComponent>
      </Container>
    </Container>
  );
};
export default TransactionForm;
