import LocalizedStrings from "react-localization";

interface ObjectString {
  [index: string]: any;
}

let strings = new LocalizedStrings({
  en: {
    /**
     * Place all the strings here to reuse and to maintain the strings in the application
     */
    VIEW: "View",
    CREATE: "Create",
    EDIT: "Edit",
    UPDATE: "Update",
    DELETE: "Delete",
    DEACTIVATE: "Deactivate",
    REACTIVATE: "Reactivate",
    ACTIVATE: "Activate",
    UNDELETE: "Undelete",
    CONTINUE: "Continue",
    CANCEL: "Cancel",
    COMPANY: "Company",
    DETAILS: "Details",
    SETTINGS: "Settings",
    LIST: "List",
    ACCOUNTS: "Accounts",
    MOBILE: "Mobile",
    WEB: "Portal",
    ERROR: "Error",
    SUBMIT_ERROR: "Submit Error",
    OK: "Ok",
    COMPLETED: "Completed",
    COMPLETED_DETAILS: "Completed Details",
    CREATED_SUCCESSFULLY: "Created Successfully",
    UPDATED_SUCCESSFULLY: "Updated Successfully",
    DEACTIVATED_SUCCESSFULLY: "Deactivated Successfully",
    CONFIRM_DELETE: "Confirm Delete",
    CONFIRM_INACTIVATE: "Confirm Inactivate",
    CONFIRM_UNDELETE: "Confirm Undelete",
    CONFIRM_ACTIVATE: "Confirm Activate",
    CONFIRM_DELETE_CAPTION: "Once deleted, it will no longer be available",
    CONFIRM_INACTIVATE_CAPTION:
      "Once deactivated, the user will be no longer be able to log in",
    CONFIRM_UNDELETE_CAPTION:
      "Once undeleted, the user will be available again",
    CONFIRM_ACTIVATE_CAPTION: "Once activated, the user will be able to log in",
    YEAR_MONTH: "Year and Month",
    ALL_COMPANIES: "All Companies",
    PAYMENTS: "Payments",
    RECEIVABLES: "Receivables",
    DEPOSIT: "Deposit",
    DEPOSIT_WITH_ENDORSEMENT: "Deposit with endorsement",
    COMPANY_ACCOUNT: "Company Account",
    ALL_COMPANY_ACCOUNTS: "All Company Accounts",
    COMPANY_ACCOUNTS: "Company Accounts",
    BANKSHOT_PORTAL: "Bankshot Portal 2.0, coming soon!",
    REACT_TYPESCRIPT: "React App using Typescript",
    ERROR_BOUNDRY_HEADER: "We ran into a problem.",
    ERROR_BOUNDRY_BODY:
      "Try to refresh your browser or use your browser back button to go back to the previous page.",
    PAGE_404_TITLE: "404",
    PAGE_404_SUBHEADER: "This page cannot be found",
    PAGE_404_MESSAGE: `The page you are looking for might have been removed,
         had its name changed, or is temporarily unavailable.`,
    LOGIN: "Login",
    LOGOUT: "Log Out",
    PROFILE: "Profile",
    PAGE_UNDER_CONSTRUCTION: "Page Under Construction",
    READ_BLOG: "Read Our Blog",
    REVIEW_CHANGELOG: "Review full changelog",
    EMAIL_ADDRESS: "Email Address",
    PASSWORD: "Password",
    BANKSHOT_SLOGAN: "Real money. Real fast. Real Secure.",
    FORGOT_PASSWORD: "Forgot Password?",
    RECOVER_PASSWORD: "Recover Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password",
    PASSWORD_ERROR_FORMAT:
      "Password must be at least 8 characters, include at least one digit, one capital letter and at least one special character (@, $, !, &, ., -, _).",
    RESET_PASSWORD: "Reset Password",
    CREATE_PASSWORD: "Create Password",
    BACKTO_LOGIN: "Back to Login",
    NOT_REGISTERED: "Not registered? ",
    WANT_TO_SEE_DEMO: "schedule a demo, now!",
    CHECK_RETENTION_ERROR_MSG:
      "Check image retention must be greater or equal than 7 days",
    DC_PRINT_PENDING_MESSAGE:
      "Check images are not available yet because the digital check has not been printed.",
    DC_PRINT_FAILED_MESSAGE:
      "Check images are not available because the digital check could not be printed, please contact support.",
    ARE_YOU_TRYING: "Are you trying to ",
    SEND_A_PAYMENT: "send a payment?",
    WEB_RECEIVABLES: {
      MAKE_PAYMENT: "Make a Payment",
      BANKSHOT_OFFERS:
        "Bank Shot offers a seamless way to pay for auxiliary services.",
      HOW_WORKS: "How it works",
      HELPS:
        "Bank Shot helps facilitate secure escrow transactions for the modern home buyer.",
      FIND_COMPANY: "Find your company",
      FIND_COMPANY_DESC:
        "You must first search for and identify your broker via a company code.",
      TXN_DETAILS: "Transaction Details",
      TXN_DETAILS_DESC:
        "Provide required transaction details including like Name, Agent, and Address.",
      AUTHENTICATE: "Authenticate",
      AUTHENTICATE_DESC:
        "Securely authenticate with your financial institution",
      SEND_PAYMENT: "Send Payment",
      SEND_PAYMENT_DESC:
        "Securely send your completed payment to your company or agency.",
      SEARCH_COMPANY: "Search for Company",
      ENTER_COMPANY_CODE: "Enter The Company Code",
      ENTER_COMPANY_CODE_PROVIDED: "To get started, enter the company code.",
      COMPANY_CODE: "Company Code",
      TRANSACTION_DETAILS: "Transaction Details",
      PROVIDE_TXN_DETAILS: "Provide the details of your transaction with ",
      AMOUNT_IS_OVER_50K:
        "Payment amount over the limit for Electronic Payment. Please use an alternative payment method.",
      CONTINUE_STEP: "Continue to next step",
      SELECT_COMPANY: "To get started, select a company",
      SELECT_YOUR_METHOD_AND_AUTHORIZE:
        "Select your payment method and authorize payment.",
    },
    DISBURSEMENT_STRING: {
      SEND_DISBURSEMENT: "Send Disbursement",
      SUBMIT_FOR_APPROVAL: "Submit For Approval",
      DISBURSEMENTS: "Disbursements",
      DISBURSEMENT_DETAILS: "Disbursement Details",
      DISBURSEMENT_ACCOUNTS: "Disbursement Accounts",
      COMPANY_ACCOUNTS: "Bank Accounts",
      PAYMENT_DETAILS: "Payment Details",
      ACCOUNTING_DETAILS: "Accounting Details",
      PAYEES_FIRST_NAME: "Payee's First Name",
      PAYEES_LAST_NAME: "Payee's Last Name",
      PAYEES_EMAIL: "Payee's Email",
      PAYMENT_AMOUNT: "Payment Amount",
      MEMO: "Memo",
      DIGITAL_CHECK: "Digital Check",
      ACH: "ACH",
      COMPANY: "Company",
      FUNDING_ACCOUNT: "Funding Account",
      DROPZONE_ATTACHMENT: "Upload an attachment to this disbursement",
      PREVIEW: "Preview",
      CREATED_SUCCESSFULLY: "Disbursement payment created successfully",
      SENDING_DISBURSEMENT_PAYMENT: "Sending disbursement payment to:",
      SAVING_DRAFT_DISBURSEMENT: "Saving draft disbursement...",
      SAVE_AS_DRAFT: "Save as Draft",
      UPDATED_DISBURSEMENT_SUCCESSFULLY:
        "Disbursement payment Updated successfully",
      DISBURSEMENT_ACCOUNT_ID: "Disbursement Account Id",
      AMOUNT: "Amount",
      PAYMENT_DATE: "Payment Date",
      STATUS: "Status",
      DIRECT_DEPOSIT_AS_DO: "Direct Deposit as Deposit Option",
      MAIL_MY_CHECK_AS_DO: "Mail My Check as Deposit Option",
      PRINT_AND_DEPOSIT_AS_DO: "Print And Deposit as Deposit Option",
    },
    HELP_STRING: {
      HELP: "Help",
      SUPPORT_CHAT: "Support Chat",
      HELP_TITLE: "Help when you need it!",
      GUIDES: "Guides",
      ARTICLES: "Articles",
      RELEASE_NOTES: "Release Notes",
      GUIDE_CONTENT: "Workflow guides to help you",
      RELEASE_CONTENT: "View recent platform changes",
      VIEW_GUIDE: "View Guides",
      VIEW_ARTICLE: "View Articles",
      VIEW_RELEASE: "View Release Notes",
      CONTACT_SUPPORT: "Contact Support",
      SEND_EMAIL:
        "Send us a support email and we will respond with in 24 hours.",
      EMAIL_SUPPORT: "Email Support",
      PHONE_SUPPORT: "Phone Support",
      SUPPORT_TIME: "Monday-Friday, 9a - 5p EST",
    },
    PROFILE_STRING: {
      PROFILE: "Profile",
      SIGNATURE: "Signature",
      USER_NAME: "User Name",
      FIRST_NAME: "First Name",
      LAST_NAME: "Last Name",
      EMAIL: "Email",
      PROFILE_DETAILS: "Profile Details",
      PASSWORD: "Password",
      NEW_PASSWORD: "New Password",
      CONFIRM_PASSWORD: "Confirm Password",
      CHANGE_PASSWORD: "Change Password",
      CHANGE_2FA: "Change Two Factor Authentication Method",
      CONFIRMATION_TITLE_CHANGE_2FA:
        "Confirm change Two Factor Authentication Method",
      CONFIRMATION_QUESTION_CHANGE_2FA:
        "Are you sure you want to change your two factor authentication method?",
      CONFIRMATION_WARNING_CHANGE_2FA:
        "If you change your two factor authentication method your old authentication will be disabled and you will need to sign in with the new one from now on. ",
      PASSWORD_FORMAT:
        "Password must be at least 8 characters, including at least one digit and at least one special character (that is not a digit or letter).",
      TWO_FACTOR_AUTH_FORMAT:
        "Select the Two Factor Authentication method you want to use, this will generate a new authentication code.",
      SAVE_SIGNATURE: "Save Signature",
      SIGNATURE_SAVED: "Signature Saved",
      SIGNATURE_STORED: "Stored successfully",
    },
    PAYMENTS_STRING: {
      CREATE_EARNEST_MONEY_PAYMENT: "Create Earnest Money Payments",
      EARNEST_MONEY_PAYMENT: "Earnest Money Payments",
      GENERATE_REPORTS: "Generate Report",
      CREATE_EARNEST_MONEY: "Create Earnest Money Payment",
      NEW_EARNEST_MONEY_PAYMENT: "New Earnest Money Payments",
      CREATE_PAYMENT: "Create Payment",
      PAYMENTS: "Payments",
      RECEIVABLES: "Receivables",
      REQUESTS: "Requests",
    },
    REPORTS_STRINGS: {
      DOWNLOAD_REPORT_CSV: "Download report as CSV",
      COMPANY_REPORTS: "Company Reports",
      REPORTS: "Reports",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      COMPANY: "Company",
    },
    MULTIFACTOR_STRINGS: {
      MULTIFACTOR_AUTHENTICATION: "Multifactor authentication",
      CONTINUE_GOOLE_AUTH: "I have the Google Authenticator, Continue",
      CONTINUE_EMAIL_2FA_AUTH: "I want to use Email 2FA instead.",
      USES_MFA:
        "Bank Shot uses multifactor authentication (MFA) as an additional user security layer.",
      CONTINUE_MFA_SIGNIN:
        "To continue signing in you must first download the Google Authenticator application.",
      OPEN_APP:
        "Open the Google authenticator app on your phone, then choose one of the pairing method ",
      STEP1: "1. Open Google Authenticator on your phone",
      STEP2: "2. Tap Scan a OR code option",
      STEP3: "3. Point your phone at this screen to capture",
      ENTER_CODE: "Enter this code from authentication app",
      ENTER_CONFIRMATION_CODE:
        "Enter confirmation code that you see on your authentication app",
      ENTER_CONFIRMATION_CODE_EMAIL:
        "Enter the confirmation code sent to your e-mail address.",
    },
    DISBURSEMENT_ACCOUNT_STRING: {
      ASSOCIATE_BANK_ACCOUNT: "Associate Bank Account",
      DISBURSEMENT_ACCOUNT_NAME: "Disbursement Account Name",
      DISBURSEMENT_ACCOUNT: "Disbursement Account",
      CREATE_DISBURSEMENT_ACCOUNT: "Create Disbursement Account",
      CREATE_RECEIVABLE_ACCOUNT: "Create Receivable Account",
      UPDATE_RECEIVABLE_ACCOUNT: "Update Receivable Account",
      UPDATE_DISBURSEMENT_ACCOUNT: "Update Disbursement Account",
      DISBURSEMENT_ACCOUNT_WITH_ID: "Disbursement account with id:",
      VERIFY: "Verify",
      CONFIRM_DEACTIVATE: "Confirm Deactivate",
      CONFIRM_INACTIVATE_CAPTION:
        "Once deactivated, the account will no longer be available for disbursements.",
      DUAL_SIGNATURE_REQUIRED: "Dual Signature Required:",
      DUAL_SIGNATURE_REQUIRED_CAPTION:
        "With this feature enabled, the disbursement will require two signatures in order to process.",
      REQUIRES_APPROVAL_TO_SUBMIT: "Requires approval to submit.",
      REQUIRES_APPROVAL_TO_SUBMIT_CAPTION:
        "If this feature is enabled, new disbursement will be  moved to “Pending Approval” until a second user rejects it or approves it and the creator cannot approve their own disbursements.",
      SIGNATURE_IS_REQUIRED:
        "In order to create disbursements from this account, you will need to add a signature",
    },
    USER_STRING: {
      USER: "User",
      CREATE_USER: "Create User",
      UPDATE_USER: "Update User",
      ACTIVATE_USER: "Activate User",
      DEACTIVATE_USER: "Deactivate User",
      UNLOCK: "Unlock",
      RESEND_INVITE: "Resend Invite",
      RESET_2FA: "Reset 2FA",
      CONFIRM_RESET_2FA: "Confirm Reset 2FA",
      CONFIRM_RESET_2FA_CAPTION:
        "If you proceed, codes generated by Google Authenticator will no longer work until the app is reconfigured. This must be done at the user's next login.",
      CONFIRM_UNLOCK_USER:
        "If you proceed, the locked user will be able to login again.",
      CLICK_CREATE_USER: "user details and click update user",
      CLICK_UPDATE_USER: "new user and click create user",
      ADD_ALL_COMPANIES: "Add All Companies",
      EMAIL_NOTIFICATION: "Email Notifications",
      RECIEVE_EMAIL: "Receive email notifications from Bank Shot",
      MOBILE2FA: "Mobile 2FA",
      ACTIVE: "Active",
      ENABLE_MOBILE2FA: "Enable mobile 2 Factor Authentication",
      ACTIVE_CAPTION: "Allow login",
      COMPANY_ACCESS: "Company Access",
      MOBILE_WEB_ACCESS: "Mobile & Portal Access",
      HOW_USER_ACCESS: "How can the User access the platform",
      RESEND_INVITE_SUCCESS_TITLE: "Invite sent successfully",
      RESEND_INVITE_SUCCESS_SUBTITLE: "The user will receive a new email",
      RESEND_INVITE_BODY_1:
        "Please confirm that you want to resend the invite to the selected user.",
      RESEND_INVITE_BODY_2:
        "Once this action is completed, the user will receive an email with a link to access the platform.",
    },
    BILLING_ACCOUNT_STRING: {
      BILLING_ACCOUNTS: "Billing Accounts",
      BILLING_ACCOUNT_DETAILS: "Billing Account Details",
      BILLING_ACCOUNT: "Billing Account",
      CREATE_BILLING_ACCOUNT: "Create Billing Account",
      UPDATE_BILLING_ACCOUNT: "Update Billing Account",
    },
    QUEUE_STRING: {
      DEPOSIT_FUNDS: "Deposit Funds",
      DEPOSIT_QUEUE: "Deposit Queue",
      DEPOSIT_FUNDS_BODY1:
        "You are depositing 10 payments for $9,000 across 6 companies.",
      DEPOSIT_FUNDS_BODY2:
        "These payments’ funds will be processed by the supporting financial institutions. ",
    },
    MY_BILLING_STRING: {
      MY_BYLLING_ACCOUNTS: "My Billing Accounts",
      BILLING_ACCOUNTS: "Billing Accounts",
      VIEW_INVOICES: "View Invoices",
      SET_IN_STRIPE: "Set up in Stripe",
      BILLING_ACCOUNT_DETAILS: "Billing Account details",
      BILLING_ACCOUNT_INVOICE_DETAILS: "Billing Account Invoice details",
      DOWNLOAD_INVOICE_PDF: "Download Invoice PDF",
      VIEW_IN_STRIPE: "View in Stripe",
    },

    WHITE_LABELS_STRING: {
      WHITE_LABELS_ACCOUNTS: "Whitelabels",
      WHITE_LABEL_DETAILS: "Whitelabel Details",
      WHITE_LABEL: "Whitelabel",
      CREATE_WHITE_LABEL: "Create whitelabel",
      UPDATE_WHITE_LABEL: "Update whitelabel",
    },

    COMPANY_NAME: "Company name",
    ACCOUNT_NUMBER: "Account Number",
    ROUTING_NUMBER: "Routing Number",
    PAYMENT_TYPE: "Payment Type",
    PROCESSOR_ACCOUNT: "Processor Account",
    DAILY_DEPOSITS_LIMIT: "Daily Deposits Limit",
    DEPOSITED_ITEM_LIMIT: "Deposited Item Limit ($)",
    DAILY_DEPOSITS_LIMIT_PER_BROKER: "Daily Deposits Limit per Broker",
    BANK_ACCOUNT_TYPE: "Bank Account Type",
    REQUIRE_VIRTUAL_ENDORSEMENT: "Require virtual endorsement",
    ENDORSEMENT_TEXT: "Endorsement text",
    UPDATE_ACCOUNT_NUMBER: "Do you want to update Account Number",
    ADDRESS_1: "Address 1",
    ADDRESS_2: "Address 2",
    CITY: "City",
    STATE: "State",
    POSTAL_CODE: "Postal code",
    LOCATOR: "Locator",
    BROKER_CODE: "Broker code",
    COMPANY_TYPE: "Company Type",
    CHECK_RETENTION: "Check Retention",
    PAYMENTS_TYPES_STRING: {
      EARNEST_MONEY: "Earnest Money",
      SECURITY_DEPOSIT: "Security Deposit",
      FEATURE_COMMISSION: "Commission",
      RENT_PAYMENT: "Rent Payment",
      HUD_EARNEST_MONEY: "Hud Earnest Money",
      OTHER: "Other",
      REMITTANCE: "Remittance",
      CLOSING_FUNDS: "Closing Funds",
      OPTION_FEE: "Option Fee",
      EARNEST_MONEY_PLUS_OPTION: "Earnest Money Plus Option",
      SURVEY_FEES: "Survey Fees",
      INVOICE_COLLECTION: "Invoice Collection",
      CLIENT_PAYMENT: "Client Payment",
      AGENT_FEES: "Agent Fees",
      HOA: "HOA",
    },
    REQUIRE_2FA: "Require 2FA",
    MOBILE_2FA: "Mobile 2FA",
    ALLOW_APP_SIGNUP: "Allow App Sign up",
    CREATE_COMPANY: "Create company",
    UPDATE_COMPANY: "Update company",
    DELETE_COMPANY: "Delete company",
    REACTIVATE_COMPANY: "Reactivate company",
    COMPANY_DETAILS: "Company details",
    USER_DETAILS: "User details",
    USER_HEADER_TITLE: "User management",
    USER_TABLE_TITLE: "Users",
    BILLING_ACCOUNT_TABLE_TITLE: "Billing Accounts",
    WHITE_LABEL_TABLE_TITLE: "Whitelabels",
    EMAIL_NOTIFICATIONS: "Email notifications",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    PRIVILEGE_LEVEL: "Privilege Level",
    USER_COMPANIES: "Mobile access companies",
    ADMIN_COMPANIES: "Portal access companies",
    PAYMENT_DETAILS: "Payments Details",
    RECEIVABLE_DETAILS: "Receivables Details",
    REQUEST_DETAILS: "Request Details",
    DEPOSIT_DETAILS: "Deposit Details",
    ACTIVE: "Active",
    TWO_FACTOR_AUTH_SETUP_TITLE: "Two Factor Authentication - Setup",
    SCREEN_WILL_SHOW_FOR_5_MIN:
      "This screen will only be shown for 5 minutes. If you need more time to install Google Authenticator, please install it at your convenience and log in again.",
    PLEASE_OPEN_GOOGLE_AUTH:
      "Please open the Google Authenticator app on your phone or tablet, then choose one of the pairing methods below.",
    SCAN_BARCODE: "Scan barcode with the camera",
    OR_USE_MANUAL_ENTRY: "...or use manual entry",
    ACCOUNT: "Account",
    KEY: "Key",
    GOOGLE_AUTH_INSTRUCTIONS:
      "When the app displays a large six digit code, click Continue, then enter the code on the following screen to confirm setup and log in.",
    ITEM_DEPOSITED_SUCCESSFULLY: "Item has been deposited successfully",
    ITEM_HOLD_SUCCESSFULLY: "Item has been held successfully",
    ITEM_REJECT_SUCCESSFULLY: "Item has been rejected successfully",
    ITEM_OVERRIDEN_SUCCESSFULLY: "Item status has been overriden successfully",
    ITEM_AMOUNT_CHANGED_SUCCESSFULLY:
      "Item amount has been changed successfully",
    UPDATED_NOTES_SUCCESSFULLY: "Updated notes successfully",
    ADDED_NOTE_SUCCESSFULLY: "Add note successfully",
    ITEM_FILE_NUMBER_CHANGED_SUCCESSFULLY:
      "Item File number has been changed successfully",
    DEPOSITED_ITEM_ID: "Deposited item id",
    CONFIRM_DEPOSIT_ACTION: "Confirm Deposit Action",
    THIS_ITEM_WILL_BE_PLACED_IN_DEPOSIT_QUEUE:
      "This item will be placed in the deposit queue.",
    HAS_NO_ACTIVE_ACCOUNTS:
      "has no active accounts for this payment type and this payment method. Please contact the portal administrator.",
    DESTINATION_ACCOUNT: "Destination Account",
    PAYMENT_METHOD: "Payment Method",
    DEPOSIT_AS: "Deposit as",
    OPTIONAL_NOTE: "Optional Note",
    CONFIRM_HOLD_ACTION: "Confirm Hold Action",
    ITEM_PLACED_ON_HOLD: "This item will be placed on hold.",
    CONFIRM_REJECT_ACTION: "Confirm Reject Action",
    ITEM_WILL_BE_REJECTED: "This item will be rejected.",
    CONFIRM_SEND_REMINDER: "Confirm Resent Reminder",
    ITEM_WILL_BE_SENT_A_REMINDER:
      "This Payment Request will be resent to the payer user ",
    OVERRIDE_ITEM_STATUS: "Override Item Status",
    SELECT_NEW_STATUS: "Select the new item Status.",
    MUST_BE_DIFFERENT_FROM_CURRENT:
      " It must be different from the current one.",
    CONFIRM_CHANGE_AMOUNT_ACTION: "Confirm Change Amount Action",
    CONFIRM_CHANGE_FILE_NUMBER_ACTION: "Confirm Change File Number Action",
    ENTER_NEW_AMOUNT: "Enter new amount in dollars",
    ENTER_NEW_FILE_NUMBER: "Enter new file number",
    EXTRA_COMMENT_FILE_NUMBER: "(Up to 32 characters.)",
    ADDITIONAL_COMMENT: "Additional comment",
    APPROVE: "Approve",
    HOLD: "Hold",
    REJECT: "Reject",
    OVERRIDE: "Override",
    SEND_REMINDER: "Send Reminder",
    COMMENT_REQUIRED: "A comment is required.",
    OVERRIDE_EXTRA_MESSAGE:
      "Any modification to an item's status may result in a change in the amount of chargeable deposits or duplicate deposit attempts, therefore, this action should be performed only as an emergency change.",
    NEW_AMOUNT: "New Amount",
    NEW_FILE_NUMBER: "New File number",
    CHANGE_AMOUNT: "Change Amount",
    CHANGE_FILE_NUMBER: "Change File number",
    ITEM_ID: "Item id",
    NOTE: "Note",
    NOTES: "Notes",
    UPDATE_NOTES: "Update Notes",
    COMMENT: "Comment",
    NEW_STATUS_MUST_BE_IN_A_DIFFERENT_STATUS:
      "New item status must be different from the current one",
    NEW_AMOUNT_MUST_BE_GREATER_THAN_0: "New amount must be greater than 0",
    ENTERED_AMOUNT_IS_NOT_IN_DOLLARS: "Entered amount is not in dollars",
    ITEM_HISTORY: "Item History",
    ADD_NOTE: "Add Note",
    PREVIOUS: "Previous",
    NEXT: "Next",
    PAGE: "Page",
    MAINTENANCE_REPORT: "Maintenance Report",
    COMPANY_MAINTENANCE_REPORT_TYPE: "companyMaintenanceReportType",
    USER_MAINTENANCE_REPORT_TYPE: "userMaintenanceReportType",
    ACCOUNT_MAINTENANCE_REPORT_TYPE: "accountMaintenanceReportType",
    DISBURSEMENT_ACCOUNT_MAINTENANCE_REPORT_TYPE:
      "disbursementAccountMaintenanceReportType",
    PLEASE_ADD_REQUIRED_ENDORSEMENT: "Please add required endorsement text",
    YOU_CAN_ADD_OPTIONAL_ENDORSEMENT: "Endorsement is optional",
    CHECK_IS_ALREADY_ENDORSED: "Check is already endorsed",
    DEPOSIT_WITHOUT_ENDORSEMENT: "Deposit Without endorsement",
    RELEASE_LIMIT_HOLD: "Release limit hold",
    RELEASE_HOLD: "Release hold",
    RELEASE_HOLD_SUBTITLE:
      "This item will be put in the queue again, you will have the opportunity to try to deposit it again.",
    ITEM_RELEASE_HOLD_SUCCESSFULLY:
      "Item has been successfully released from hold",
    ITEM_HAS_NO_ACCOUNTS:
      "Item is not associated with any account and cannot be deposited",
    RETURN_TITLE: "Return item to the payments list",
    RETURN_SUBTITLE:
      "This item will be returned to a read status and will be removed from the deposit queue",
    RETURN: "Return",
    ITEM_RETURNED_SUCCESSFULLY: "item successfully returned to payment list",
    RESET_2FA: "Reset 2FA",
    CONFIRM_2FA_RESET: "Confirm 2FA Reset",
    SUCCESSFULLY_RESET_2FA: "Successfully reset 2FA for this user",
    YOU_HAVE_TO_ENTER_YOUR_PASSWORD:
      "For security, you have to enter your password to reset the 2FA configuration",
    TWO_FACTOR_AUTHENTICATION: "Two Factor Authentication",
    TWO_FACTOR_AUTH_CONFIRMATION_TEXT:
      "When you click confirm, an email will be sent to your address with a link to reset your 2FA. That link will be valid for 5 minutes, if you run out of time, you can request more 2FA resets, but after confirm the reset you have to wait 7 days to reset your 2FA configuration again.",
    TWO_FACTOR_AUTH_RESET_CONFIRMATION:
      "Two Factor Authentication Reset Confirmation",
    CONFIRM: "Confirm",
    SELF_RESET_2FA_EMAIL_HAS_BEEN_SENT: "Self reset 2FA email has been sent",
    PLEASE_CHECK_YOUR_EMAIL: "Please check your email inbox",
    SESSION_HAS_TIMED_OUT: "Session has timed out",
    PLEASE_RE_ENTER_YOUR_CREDENTIALS:
      "Please re-enter your credentials to login again.",
    INDIVIDUAL_PRIVILEGES: "Individual Privileges",
    BILLING_ACCOUNT_CANT_BE_UPDATED: "Billing account can't be updated",
    STRIPE_SUB_NOT_ACTIVE: "Stripe subscription is not active",
    BILLING_ACCOUNT_HAS_NO_STRIPE_SUB:
      "This billing account has no Stripe Subscriptions and can't be updated",
    BILLING_ACCOUNT_HAS_NO_ACTIVE_STRIPE_SUB:
      "This billing account can't be updated because it has not active Stripe subscriptions",
    CONFIRM_RESET_PASSWORD: "Continue reset",
    CONFIRM_RESTET_2FA: "Confirm2FAReset",
    REJECT_TITLE_MODAL: "Reject Draft Disbursement Payment",
    THIS_ACTION_CANNOT_BE_UNDONE: "this action cannot be undone.",
    REVIEW_APPROVE_DISBURSEMENT_TITLE_MODAL: "Approve Disbursement Payment",
    REVIEW_REJECT_DISBURSEMENT_TITLE_MODAL: "Reject Disbursement Payment",
    VOID_DISBURSEMENT_TITLE_MODAL: "Void Disbursement Payment",
    EXTRA_STEP_EXPLANATION:
      "This is an extra step to add a custom institution name for an institution not supported by PLAID, you can leave it empty if you wish.",
    PLEASE_ENTER_A_VALID_EMAIL: "Please enter a valid email",
    EMAIL_ADDRESSES_DO_NOT_MATCH: "Email addresses do not match",
    CONFIRM_EMAIL: "Confirm Payee's Email",
    REJECT_DRAFT_DISBURSEMENT_WITH_ID:
      "Are you sure you want to reject this draft payment with id:",
    REJECT_DISBURSEMENT_WITH_ID:
      "Are you sure you want to reject this payment with id:",
    APPROVE_DISBURSEMENT_WITH_ID:
      "Are you sure you want to approve this payment with id:",
    VOID_DISBURSEMENT_WITH_ID:
      "Are you sure you want to void this item payment with id:",
    DISBURSEMENT_DETAILS: "Disbursement Details",
    THIS_PAYMENT_STILL_NOT_PROCESSED: "This payment has not been sent.",
    NO_MEMO: "No memo has been placed in this check.",
    CREATE_MULTIPLE_DISBURSEMENTS: "Create Multiple Disbursements",
    TEMPLATE_SUCCESSFULLY_DOWNLOADED: "Template successfully downloaded",
    AVAILABLE_ACCOUNTS_SUCCESSFULLY_DOWNLOADED:
      "Available Accounts successfully downloaded",
    FILE_UPLOAD_ERROR: "File upload error",
    FILE_UPLOAD_ERROR_DETAILS:
      "There was an error uploading your file. \n Please ensure that the file you are attempting to upload meets the required format and size limitations. If the issue persists, please try again later or contact our support team for further assistance.",
    SENDING_DISBURSEMENT_PAYMENTS: "Sending disbursement payments",
    SEND_DISBURSEMENTS: "Send Disbursements",
    RESET_DATA: "Reset Data",
    INITIAL_CHECK_NUMBER: "Initial Check Number",
    DIGITAL_CHECK_NUMBER_HELP:
      "This is the next check number for the current funding account.",
    CHECK_NUMBER_FOR_THIS_DISBURSEMENT:
      "This is the check number for this disbursement.",
    THIS_PAYMENT_HAS_NOT_CHECK_NUMBER:
      "This payment has not a check number associated.",
    UPDATE_CUSTOMER: "Update Customer",
    UPDATE_CUSTOMER_DISCLAIMER:
      "This will change the unique customer identifier of the billing account, please check first if the customer identifier exists in stripe with the button",
    SEARCH_MY_ADDRESS: "Search my Address",
    ENTER_YOUR_EMAIL_INFO:
      "This information will be used to send you a confirmation email.",
    ENTER_YOUR_EMAIL: "Email",
    ENTER_YOUR_FIRST_NAME: "First Name",
    ENTER_YOUR_LAST_NAME: "Last Name",
    SEND_PAYMENT_REQUEST: "Send Payment Request",
    EMD_REQUEST_SENT_TO: "Request for Earnest Money has been sent to",
    PAYER_DETAILS: "Payer Details",
    PHONE_NUMBER: "Phone Number",
    SEND: "Send",
    SELLER_NAME: "Seller name",
    FILE_NUMBER: "File Number",
    PROPERTY_DETAILS: "Property Details",
    TRANSACTION_DETAILS: "Transaction Details",
    PAYMENT_AMOUNT: "Payment Amount",
    COMPANY_HAS_NO_AGENTS: "This company does not have available agents.",
    COPY_URL: "Copy URL",
    RECEIVABLE_ACCOUNT_VERIFICATION_NEEDED:
      "Account Verification Needed: Hey! We've sent two tiny deposits to your bank account, just a few cents. Check your account, note the amounts, and fill out the two transaction value fields below. Thanks for helping us keep things secure!",
    CONFIRM_DEPOSIT: "Confirm Deposit",
    SURE_TO_MAKE_THE_DEPOSIT:
      "Please confirm you would like to make the deposit.",
    IRREVERSIBLE_ACTION: "This is an irreversible action.",
    SEND_MULTIPLE_PAYMENT_REQUEST: "Send Multiple Payment Requests",
    DOWNLOAD_TEMPLATE_FOR_MULTIPLE_PAYMENT_REQUESTS:
      "Download the template, then fill in the required information, export as CSV, attach the file and click “Submit Requests for Payments”",
    SENDING_PAYMENT_REQUESTS: "Sending Payment Requests",
    MULTIPLE_RFP_STRING: {
      PAYMENT_TYPE: "Payment Type",
      AMOUNT: "Amount",
      COMPANY_ID: "Company ID",
      PAYER_FIRST_NAME: "Payer First Name",
      PAYER_LAST_NAME: "Payer Last Name",
      PAYER_EMAIL_ADDRESS: "Payer Email Address",
      CHECK_CAPTURE_AS_PM: "Check Capture as Payment Method",
      SAME_DAY_ACH_AS_PM: "Electronic Payment as Payment Method",
    },
    VALIDATE_FUNDS_ACTION: "Validate Funds Action",
    FUNDS_VALIDATION_INITIATED: "Validation of funds initiated",
    CONFIRM_DELETE_ACTION: "Confirm Delete Action",
    RECURRING_REQUEST_WILL_BE_DELETED:
      "This recurring request will be deleted and will no longer be available.",
    ENTITY_ID: "Entity ID",
    SSO_SERVICE: "SSO Service",
    IDP_CERTIFICATE: "IDP Certificate",
    REDIRECT_URL: "Redirect URL",
    LOGIN_WITH_GOOGLE: "Google",
    LOGIN_WITH_MICROSOFT: "Microsoft",
    DELUXE_ACCOUNTS: "Deluxe accounts",
    DELUXE_ACCOUNT: "Deluxe Account",
    CLEAR_SUBMIT_ATTEMPT_TITLE: "Clear submit attempt for item",
    CLEAR_SUBMIT_ATTEMPT_SUBTITLE:
      "This item will be returned to a New status and the Submit Attempt transactions will be removed from the History log, this will allow the user to retry the submit.",
    CLEAR_SUBMIT_ATTEMPT: "Clear Submit Attempt",
    MARK_AS_COMPLETED_TITLE: "Mark As Completed",
    MARK_AS_COMPLETED_SUBTITLE:
      "This wire will be mark as completed and the submitter user will get a notification",
    MARK_AS_COMPLETED: "Mark As Completed",
  },
});

export const addStrings = (obj: object, lang: string = "en") => {
  const original = strings;
  strings.setContent({
    [lang]: mergeDeep(original, obj),
  });
  return strings;
};

const isObject = (item: object | string) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

function mergeDeep(source: ObjectString, target: ObjectString) {
  let output: ObjectString = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, {
            [key]: source[key],
          });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, {
          [key]: source[key],
        });
      }
    });
  }
  return output;
}

export default strings;
