import { Container, Icon, TypoGraph } from "components";

import styles from "./companyLogo.module.scss";
import { IWhitelabelConfigurationResponse } from "interfaces/IWhitelabelConfigurationResponse";

interface ICompanyLogoComponentProps {
  whitelabelConfig?: IWhitelabelConfigurationResponse;
}

const CompanyLogoComponent = (props: ICompanyLogoComponentProps) => {
  const { whitelabelConfig } = props;
  const logoBase64 = whitelabelConfig?.logoBase64;
  const slogan = whitelabelConfig?.configJson.subtitle
    ? whitelabelConfig?.configJson.subtitle
    : "the good funds payment portal";

  return (
    <Container className={styles.logoAndSloganContainer}>
      <Icon
        type="images"
        url={`data:image/png;base64,${logoBase64}`}
        height={65}
      />
      <TypoGraph variant={"h2"} content={slogan}></TypoGraph>
    </Container>
  );
};

export default CompanyLogoComponent;
