import {
  Button,
  Card,
  Container,
  HeaderBar,
  Icon,
  TypoGraph,
} from "components";
import { Link } from "react-router-dom";
import { ObjectStrings } from "interfaces";
import strings from "strings";
import colors from "theme/colors";
import styles from "./help.module.scss";

interface IHelpProps {}

const HelpScreen: React.FC<IHelpProps> = (props) => {
  const token: string | null = localStorage.getItem("token");

  const t = strings.HELP_STRING;

  const CardItem = (props: ObjectStrings) => {
    const { iconName, title, content, btnLabel, link } = props;
    return (
      <Card
        sxContent={{
          display: "grid",
          gridTemplateRows: "4rem 2.5rem 3rem 3rem",
        }}
      >
        <Icon name={iconName} size={30} />
        <TypoGraph variant="h2" content={title} align="center" />
        <TypoGraph content={content} align="center" color={colors.grey40} />
        <Button
          variant="outlined"
          label={btnLabel}
          className={styles.cardBtn}
          onClick={() => window.open(link, "_blank")}
        />
      </Card>
    );
  };

  return (
    <Container>
      <HeaderBar title={t.HELP} />
      <Container className={styles.bannerContainer}>
        <Container className={styles.logoContainer}>
          <Icon
            name="icon_bg_logo"
            width={550}
            height={400}
            color="#d4d2e64d"
          />
        </Container>
        <Container className={styles.helpTitleContainer}>
          <Icon name="icon_help" size={56} boxClassName={styles.helpIcon} />
          <TypoGraph
            variant="h1"
            content={t.HELP_TITLE}
            color={colors.white}
            align="center"
          />
        </Container>
      </Container>
      <Container className={styles.helpCardContainer}>
        <CardItem
          iconName="help_guides"
          title={t.GUIDES}
          content={t.GUIDE_CONTENT}
          btnLabel={t.VIEW_GUIDE}
          link="https://bankshot.zendesk.com/hc/en-us"
        />
        <CardItem
          iconName="help_release"
          title={t.RELEASE_NOTES}
          content={t.RELEASE_CONTENT}
          btnLabel={t.VIEW_RELEASE}
          link="https://bankshot.zendesk.com/hc/en-us/sections/26506458858523-Release-Notes"
        />
        <Card
          sxContent={{
            display: "grid",
            gridTemplateRows: "3rem 8rem 2rem 1rem",
          }}
        >
          <Icon name="help_contact" size={30} />
          <Container>
            <TypoGraph
              variant="h2"
              content={t.CONTACT_SUPPORT}
              align="center"
            />
            <TypoGraph
              content={t.SEND_EMAIL}
              align="center"
              color={colors.grey40}
            />
            <TypoGraph
              content="support@getbankshot.com"
              align="center"
              color={colors.primary}
            />
          </Container>
          <Link
            to="#"
            style={{ textDecoration: "none", display: "flex" }}
            onClick={(e) => {
              window.location.href = "mailto:support@getbankshot.com";
              e.preventDefault();
            }}
          >
            <Button
              variant="outlined"
              label={t.EMAIL_SUPPORT}
              className={styles.cardBtn}
            />
          </Link>
        </Card>
      </Container>
    </Container>
  );
};

export default HelpScreen;
